<template>
  <header class="header p-3">
    <div
      class="logo-rs"
      :class="$i18n.locale"
    />

    <div
      class="logo"
      :class="$i18n.locale"
    />
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  border-bottom: 1px solid lightgrey;

  .logo {
    width: 200px;
    height: 67px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    &.sl {
      background-image: url('../assets/images/DVK_logo_si.svg');
    }

    &.en {
      background-image: url('../assets/images/DVK_logo_en.svg');
      width: 214px;
    }

    &.hu {
      background-image: url('../assets/images/DVK_logo_hu.svg');
      width: 235px;
    }

    &.it {
      background-image: url('../assets/images/DVK_logo_it.svg');
      width: 261px;
    }

    @media (max-width: 855px) {
      height: 41px;

      &.sl {
        background-image: url('../assets/images/DVK_logo_mobile_si.svg');
      }

      &.en {
        background-image: url('../assets/images/DVK_logo_mobile_en.svg');
        width: 214px;
      }

      &.hu {
        background-image: url('../assets/images/DVK_logo_mobile_hu.svg');
        width: 235px;
      }

      &.it {
        background-image: url('../assets/images/DVK_logo_mobile_it.svg');
        width: 261px;
      }
    }
  }

  .logo-rs {
    width: 158px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: fixed;
    z-index: 600;
    top: 0;
    right: 0;
    margin-right: 16px;
    margin-top: 27px;

    @media (max-width: 855px) {
      display: none;
    }

    &.sl {
      background-image: url('../assets/images/RS_logo_si.svg');
    }

    &.en {
      background-image: url('../assets/images/RS_logo_en.svg');
    }

    &.hu {
      background-image: url('../assets/images/RS_logo_hu.svg');
    }

    &.it {
      background-image: url('../assets/images/RS_logo_it.svg');
    }
  }
}
</style>
