<template>
  <div class="locale-changer">
    <ul>
      <li
        :class="{ active: $i18n.locale == 'si' }"
        @click="switchLocale('sl')"
      >si</li>

      <li
        :class="{ active: $i18n.locale == 'en' }"
        @click="switchLocale('en')"
      >en</li>

      <li
        :class="{ active: $i18n.locale == 'it' }"
        @click="switchLocale('it')"
      >it</li>

      <li
        :class="{ active: $i18n.locale == 'hu' }"
        @click="switchLocale('hu')"
      >hu</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        this.$router.push('/' + locale)

        document.querySelector('html').setAttribute('lang', this.$i18n.locale)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .locale-changer {
    position: fixed;
    z-index: 500;
    right: 0;
    top: 0;
    margin-top: 55px;
    margin-right: 16px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-transform: uppercase;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      letter-spacing: 0.04em;
      font-size: 16px;
      line-height: 1;

      li {
        color: #283b45;
        cursor: pointer;

        &.active {
          font-weight: 700;
        }

        &:before {
          content: "|";
          color: #bbbdbf;
          text-align: center;
          display: inline-block;
          vertical-align: top;
          position: relative;
          top: -0.1em;
          min-width: 1em;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 855px) {
      margin-top: 14px;
    }
  }
</style>
