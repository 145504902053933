import axios from 'axios'

const actions = {
  loadNotifications ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/fileadmin/dvk_maps/notifications.json').then((response) => {
        commit('SET_NOTIFICATIONS', { notifications: response.data })
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

export default actions
