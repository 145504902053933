// import Vue from 'vue'
import Vuex from 'vuex'

import settings from './modules/settings/store.js'
import notifications from './modules/notifications/store.js'

export default new Vuex.Store({
  modules: {
    settings: settings,
    notifications: notifications
  }
})
