<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$i18n.locale': function () {
      this.setAppTitle()
    }
  },
  mounted() {
    this.setAppTitle()
    this.setHtmlLang()
  },
  methods: {
    setAppTitle: function () {
      document.title = this.$t('common.app_title')
    },
    setHtmlLang: function () {
      document.querySelector('html').setAttribute('lang', this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: 'Officina Sans ITC Pro';
}

body {
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
  width: 100vw;
  font-family: 'Officina Sans ITC Pro';
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
	}
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
