import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/styles/main.css'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
import { createRouter, createWebHashHistory } from 'vue-router'
import FloatingVue from 'floating-vue'
import MapComponent from './components/MapComponent.vue'
import store from './store'
import VueMatomo from 'vue-matomo'

import App from './App.vue'

import moment from 'moment'
import locale from './locale.js'

import 'floating-vue/dist/style.css'

moment.locale('si')

let app = createApp(App)

app.config.globalProperties.$moment=moment

const i18n = createI18n({
  locale: 'sl', // set locale
  fallbackLocale: 'sl', // set fallback locale
  messages: locale, // set locale messages
})

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/:locale?',
      component: MapComponent,
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale
        const supported_locales = ['sl', 'en', 'it', 'hu']
        if (!supported_locales.includes(locale)) {
          return next()
        }

        if (i18n.global.locale !== locale) {
          i18n.global.locale = locale;
          document.querySelector('html').setAttribute('lang', locale)
        }
        return next()
      }
    },
  ],
})

app.use(i18n)
app.use(router)

if (process.env.NODE_ENV === 'production') {
  app.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_URL,
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    router: router
  })
}
app.use(FloatingVue)
app.use(VueAxios, axios)
app.use(Notifications)
app.use(store)
app.mount('#app')
