<template>
  <div v-if="notifications[$i18n.locale]?.length > 0">
    <h2>{{ $t('notifications.title') }}</h2>
    <p
      v-for="(notification, index) in notifications[$i18n.locale]"
      :key="index"
      v-html="notification.content"
    ></p>

    <hr>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Notification',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications
    })
  },
  watch: {
    '$i18n.locale': function () {
      this.$store.dispatch('notifications/loadNotifications')
    }
  },
  async mounted() {
    this.$store.dispatch('notifications/loadNotifications')
  }
}
</script>

<style lang="scss">
.notification {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    line-height: 20px;
    right: 10px;
    text-align: center;
    border: solid gray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    cursor: pointer;
  }

  background-color: #eaf1f5 !important;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  padding: 15px;
  margin: 10px 0 10px 10px;

  .notification-title {
    text-transform: uppercase;
  }

  .notification-content {
    font-size: 14px;
    font-family: 'Officina Sans ITC Pro';
  }
}

.vn-fade-enter-active, .vn-fade-leave-active, .vn-fade-move  {
  transition: all .5s ease !important;
}
.vn-fade-enter-from, .vn-fade-leave-to {
  opacity: initial !important;
  margin-left: -400px !important;
}
</style>
