<template>
  <HeaderComponent />
  <LanguageComponent />
  <div class="search d-flex">
    <input
      class="street"
      type="text"
      :placeholder="$t('search.placeholder.street')"
      v-model="search_string"
      @input="searchStreet"
      @keyup.enter="searchStreetEnter"
    >
    <div class="reset" @click="resetSearch">×</div>

    <div class="search-not-found" v-if="this.searchNotFound" v-html="$t('search.not_found')"></div>

    <!--<input class="house-number" type="text" :placeholder="$t('search.placeholder.house_number')">

    <button
      class="btn btn-sm btn-primary"
    >
      {{ $t('search.btn_search') }}
    </button>-->
    <div class="search-result" v-if="street_search_results.length > 0 && show_street_search_results">
      <ul>
        <li
          v-for="street in street_search_results"
          :key="street.id"
        >

          <strong v-if="street.ul_uime === street.settlement.na_uime || street.municipality.ob_uime === street.settlement.na_uime">{{ street.ul_uime }}, {{ street.municipality.ob_uime }}</strong>
          <strong v-else>{{ street.ul_uime }}, {{ street.settlement.na_uime }}, {{ street.municipality.ob_uime }}</strong>

          <ul
            class="nested"
          >
            <li
              v-for="house_number in house_number_search_results[street.ul_mid]"
              :key="house_number.id"
              @click="showHouseNumberOnMap(house_number, street)"
            >
              {{ street.ul_uime }} {{ house_number.hs_full }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div
    v-if="!show_info_box"
    class="settings-control info-icon"
    @click="show_info_box = !show_info_box"
  >
    i
  </div>

  <Transition name="info-box">
    <div
      v-if="show_info_box"
      class="info-box"
    >
      <div
          class="close"
          @click="show_info_box = !show_info_box"
        >
        <span class="arrow" />
      </div>
      <div v-if="map_overrides.enabled && map_overrides[$i18n.locale].title !== ''">
        <span class="pre-label">
          <h2>{{ map_overrides[$i18n.locale].title }}</h2>
          <p v-html="map_overrides[$i18n.locale].description" />
        </span>

        <div class="clearfix" />
        <Toggle
          v-model="toggle_value_early_elections_polling_stations"
          :off-label="$t('map_overrides.off')"
          :on-label="$t('map_overrides.on')"
          @change="toggleExtraPollingStations()"
        />

        <hr>
      </div>

      <NotificationComponent class="pre-label" />

      <div class="legend pre-label">
        <h2>{{ $t('legend.name') }}</h2>
        <ul>
          <li class="search-location">
            {{ $t('legend.search_location') }}
          </li>
          <li class="circle circle-high">
            {{ $t('legend.high') }}
          </li>
          <li class="circle circle-medium">
            {{ $t('legend.medium') }}
          </li>
          <li class="circle circle-low">
            {{ $t('legend.low') }}
          </li>
          <li class="your-polling-station">
            {{ $t('legend.your_polling_station') }}
          </li>
          <li class="your-early-polling-station" v-if="map_overrides.enabled">
            {{ $t('legend.your_early_polling_station') }}
          </li>
          <li class="home-location">
            {{ $t('legend.home_location') }}
          </li>
          <li class="circle circle-current-location">
            {{ $t('legend.current_location') }}
          </li>
        </ul>

        <hr>
      </div>
    </div>
  </Transition>

  <div id="map" />
</template>

<script>
import L from 'leaflet'
import { GeoSearchControl, OpenStreetMapProvider, BingProvider} from 'leaflet-geosearch'
import 'leaflet-geometryutil'
import 'leaflet-routing-machine'
import 'leaflet.locatecontrol'
import 'leaflet.markercluster'

import 'leaflet/dist/leaflet.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import Toggle from '@vueform/toggle'
import '@vueform/toggle/themes/default.css'

import HeaderComponent from './HeaderComponent.vue'
import LanguageComponent from './LanguageComponent.vue'
import NotificationComponent from './NotificationComponent.vue'

import { mapState } from 'vuex'

var map
var pollingStationMarkerGroup
var markerGroupEarlyElections
var locateControl
var closestLocation
var routingControl = null
var municipalities
var houseNumberMarkerGroup

class CustomBingProvider extends BingProvider {
  endpoint({ query, jsonp }) {
    query = query + ', Slovenia'

    const params = typeof query === 'string' ? { q: query } : query
    params.jsonp = jsonp;

    return this.getUrl(this.searchUrl, params)
  }
}

export default {
  name: 'Map',
  components: {
    Toggle,
    HeaderComponent,
    LanguageComponent,
    NotificationComponent
  },
  data() {
    return {
      toggle_value_early_elections_polling_stations: false,
      show_info_box: true,
      search_string: '',
      street_search_results: [],
      house_number_search_results: [],
      street_search_in_progress: false,
      street_search_timeout: [],
      show_street_search_results: false,
      searchNotFound: false
    }
  },
  computed: {
    ...mapState({
      last_update: state => state.settings.settings.last_update,
      map_overrides: state => state.settings.settings.map_overrides
    })
  },
  watch: {
    '$i18n.locale': function () {
      document.querySelector('.search input').placeholder = this.$t('search.placeholder.street')
      document.querySelector('.footer-copyright').innerHTML = `${this.yearSince()} ${this.$t('common.copyright')}`
      document.querySelector('.last-update').innerHTML = `${this.$t('common.last_update')} ${this.last_update}`
    }
  },
  async mounted() {
    await this.$store.dispatch('settings/loadSettings')
    this.initMap()
  },
  methods: {
    searchStreetEnter: function() {
      const first_element = document.querySelector('.search-result .nested > li')

      if (first_element !== null) {
        first_element.click()
      }
    },
    resetSearch: function () {
      this.show_street_search_results = false
      this.search_string = ''
      this.street_search_results = []
      this.house_number_search_results = []
      houseNumberMarkerGroup.clearLayers()
      this.showAllPollingStationsMarkers()
      this.searchNotFound = false
    },
    showAllPollingStationsMarkers: function () {
      pollingStationMarkerGroup.eachLayer(function (layer) {
        layer.setOpacity(1)
      })
    },
    showHouseNumberOnMap: function (house_number, street) {
      houseNumberMarkerGroup.clearLayers()
      this.showAllPollingStationsMarkers()
      this.show_street_search_results = false
      const house_number_latlng = L.latLng(house_number.latitude, house_number.longitude)

      var street_name = `${ street.settlement.na_uime }, ${ street.municipality.ob_uime }`
      if (street.ul_uime === street.settlement.na_uime || street.municipality.ob_uime === street.settlement.na_uime) {
        street_name = `${ street.municipality.ob_uime }`
      }

      const the_marker = L.marker([house_number.latitude, house_number.longitude], { icon: this.greenMarker() }).addTo(houseNumberMarkerGroup).bindPopup(`
        <b>${street.ul_uime} ${house_number.hs_full}, ${ street_name }</b>
      `, {closeOnClick: false, autoClose: false})

      setTimeout(function () {
        the_marker.openPopup()
      }, 500)

      if (this.toggle_value_early_elections_polling_stations) {
        map.flyTo(house_number_latlng, 15)
      } else {
        let that = this

        pollingStationMarkerGroup.eachLayer(function (layer) {

          if (layer.options.n8 === house_number.polling_station.n8) {
            that.drawRoute(house_number_latlng, layer.getLatLng())

            setTimeout(function () { layer.openPopup(layer.getLatLng())}, 500)
          } else {
            layer.setOpacity(0)
          }
        })
      }
    },
    searchHouseNumber: function (ul_mid) {
      this.axios.get(`${process.env.VUE_APP_HOUSE_NUMBER_SEARCH_API}/${ul_mid}/${this.search_string}`)
        .then(response => {
          this.house_number_search_results[ul_mid] = response.data
        })
    },
    searchStreet: function () {
      if (this.search_string.length >= 3 && this.street_search_in_progress === false) {
        this.searchNotFound = false

        let that = this
        this.street_search_in_progress = true
        clearTimeout(this.street_search_timeout)

        this.street_search_timeout = setTimeout(function () {
          that.axios.get(`${process.env.VUE_APP_STREET_SEARCH_API}/${that.search_string}`)
           .then(response => {
             that.street_search_results = response.data
             that.house_number_search_results = []

              for (let street_search_result of that.street_search_results) {
                that.searchHouseNumber(street_search_result.ul_mid)
              }

              that.street_search_in_progress = false
              that.show_street_search_results = true

              if (that.street_search_results.length === 0) {
                that.searchNotFound = true
              }
          })
        }, 1000)
      }
    },
    initMap: function () {
      this.initMarkers()
      let that = this

      map = L.map('map', { zoomControl: false, minZoom: 8, tap: false }).setView(
        process.env.VUE_APP_MAP_CENTER.split(','),
        process.env.VUE_APP_MAP_DEFAULT_ZOOM
      )

      L.tileLayer(
        "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: `
            <span class="last-update">${this.$t('common.last_update')} ${this.last_update}</span>
            <span class="footer-copyright">&copy; ${this.yearSince()} <a href="https://dvk-rs.si" target="_blank">${this.$t('common.copyright')}</a></span>
            <span class="osm">&copy; <a href="https://www.openstreetmap.org/copyright/sl" target="_blank">OpenStreetMap contributors</a></span>
            <span class="footer-copyright"><a href="https://transmedia-design.me" target="_blank">ITD</a></span>
          `,
          // maxZoom: process.env.VUE_APP_MAP_MAX_ZOOM,
          className: 'map-tiles'
        }
      ).addTo(map)

      map.attributionControl.setPrefix(false)
      //map.on('locationfound', this.onLocationFound)
      map.on('geosearch/showlocation', this.onLocationFound)

      map.on('zoom', function () {
        if (map.getZoom() <= 12) {
          that.showAllPollingStationsMarkers()
        }
      })

      pollingStationMarkerGroup = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 13
      }).addTo(map)
      markerGroupEarlyElections = L.markerClusterGroup().addTo(map)
      houseNumberMarkerGroup = L.markerClusterGroup().addTo(map)

      this.initVolisca()

      //this.initMunicipalities()
      this.initControls()
      // this.initCurrentLocation()
      //this.initSearchBar()
    },
    initMunicipalities: function () {
      let that = this
      municipalities = L.geoJson(null, {
        style: function (feature) {
          return {
            color: '#283b45',
            fill: true,
            opacity: 0.8,
            weight: 1,
            fillColor: 'transparent'
          }
        },
        onEachFeature(feature, layer) {
          layer.on('mouseover', function () {
            // if (map.getZoom() < 13) {
              this.setStyle({
                'fillColor': '#0000ff'
              })

              console.log(feature.properties)
              layer.bindPopup(`${feature.properties}`).openPopup()
            // }
          })

          layer.on('mouseout', function () {
            this.setStyle({
              'fillColor': 'transparent'
            })

            layer.closePopup()
            layer.unbindPopup()
          })

          layer.on('click', function () {
            map.setView(layer.getBounds().getCenter(), 13)
            this.setStyle({
              'fillColor': 'transparent'
            })

            layer.closePopup()
            layer.unbindPopup()

            //that.initVolisca()
          })
        }
      })

      this.axios.get('/fileadmin/dvk_maps/VO.geojson')
        .then(response => {
          //console.log(response.data)
          municipalities.addData(response.data);
      })

      municipalities.addTo(map)
    },
    initVolisca: function () {
      if (
        pollingStationMarkerGroup.getLayers().length === 0 &&
        this.toggle_value_early_elections_polling_stations == false
      ) {
        this.axios.get(`${process.env.VUE_APP_POLLING_STATIONS_API}/?cid=1`)
          .then(response => {
            response.data.forEach(location => {
              if (location.latitude !== '' && location.longitude !== '') {
                L.marker([location.latitude, location.longitude], { n8: location.n8 }).addTo(pollingStationMarkerGroup).bindPopup(`
                  <b><small>(${location.n8})</small> ${location.d34_uime}</b>
                  <br>
                  ${location.address}
                  <br>
                  ${location.description}
                `, {closeOnClick: false, autoClose: false})
              }
            })
        })
      }

      if (
        markerGroupEarlyElections.getLayers().length === 0 &&
        this.toggle_value_early_elections_polling_stations
      ) {
        this.axios.get(`${process.env.VUE_APP_POLLING_STATIONS_API}/?cid=2`)
          .then(response => {
            response.data.forEach(location => {
              if (location.latitude !== '' && location.longitude !== '') {
                L.marker([location.latitude, location.longitude], { icon: this.greyMarker() }).addTo(markerGroupEarlyElections).bindPopup(`
                  <b><small>(${location.n8})</small> ${location.d34_uime}</b>
                  <br>
                  ${location.address}
                  <br>
                  ${location.description}
                `)
              }
            })
        })
      }
    },
    resetVolisca: function () {
      markerGroupEarlyElections.clearLayers()
      pollingStationMarkerGroup.clearLayers()
      this.initVolisca()
    },
    initMarkers: function () {
      delete L.Icon.Default.prototype._getIconUrl

      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
      })
    },
    yearSince: function () {
      const current_year = this.$moment().year()

      if (current_year !== parseInt(process.env.VUE_APP_SINCE)) {
        return process.env.VUE_APP_SINCE + ' - ' + current_year
      }

      return process.env.VUE_APP_SINCE
    },
    initControls: function () {
      L.control.zoom({ position: 'bottomright' }).addTo(map)
      locateControl = L.control.locate({
        position: 'bottomright',
        showPopup: false,
      }).addTo(map)
    },
    initCurrentLocation: function () {
      locateControl.start()
    },
    onLocationFound: function (e) {
      let location_latlng

      if (typeof e.marker === 'undefined') {
        location_latlng = e.latlng
      } else {
        location_latlng = e.marker._latlng
      }

      // if (e.location.raw.class === 'building' || e.location.raw.type === 'house') {
        setTimeout(() => {
          closestLocation = L.GeometryUtil.closestLayer(map, pollingStationMarkerGroup.getLayers(), location_latlng)

          this.drawRoute(location_latlng, closestLocation.latlng, e.marker.getPopup().getContent())

          closestLocation.layer.openPopup()
        }, 500)
      // } else {
      map.flyTo(location_latlng, 15)
      // }

    },
    initSearchBar: function () {
      const bingprovider = new CustomBingProvider({
        params: {
          key: process.env.VUE_APP_BING_API_KEY,
        },
      })

      const osmprovider = new OpenStreetMapProvider({
        params: {
          countrycodes: 'si',
        }
      })

      new GeoSearchControl({
        provider: bingprovider,
        resultFormat: ({ result }) => {
          if(result.raw.address.countryRegion === 'Slovenia' && result.raw.confidence !== 'Low') {
            return result.label.replace('Slovenia', 'Slovenija')
          }

          return ''
        },
        popupFormat: ({ query, result }) => {
          // console.log(result.raw)
          let additionalText = ''
          if(result.raw.entityType !== 'Address') {
            additionalText = '<br><br><b>OPOMBA!</b><br>Vpisan naslov ni natančen zato lahko prihaja do nenatančnega prikaza poti do volišča!'
          }

          return '<b>' + result.label.replace('Slovenia', 'Slovenija') + '</b>' + additionalText
        },
        style: 'bar',
        autoComplete: true,
        searchLabel: this.$t('search_bar.type_address'),
        showPopup: true,
        notFoundMessage: '<small>Naslova ni bilo mogoče najti.</small>',
        retainZoomLevel: true,
        autoClose: false,
        zoomLevel: 13,
        marker: {
          icon: this.greenMarker()
        }
      }).addTo(map)

      document.querySelector('.leaflet-control-geosearch input').setAttribute('inputmode', 'search')
    },
    greenMarker: function () {
      return new L.Icon({
        iconRetinaUrl: require('@/assets/images/markers/marker-icon-2x-green.png'),
        iconUrl: require('@/assets/images/markers/marker-icon-green.png'),
        shadowUrl: require('@/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    },
    greyMarker: function () {
      return new L.Icon({
        iconRetinaUrl: require('@/assets/images/markers/marker-icon-2x-grey.png'),
        iconUrl: require('@/assets/images/markers/marker-icon-grey.png'),
        shadowUrl: require('@/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    },
    orangeMarker: function () {
      return new L.Icon({
        iconRetinaUrl: require('@/assets/images/markers/marker-icon-2x-orange.png'),
        iconUrl: require('@/assets/images/markers/marker-icon-orange.png'),
        shadowUrl: require('@/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    },
    violetMarker: function () {
      return new L.Icon({
        iconRetinaUrl: require('@/assets/images/markers/marker-icon-2x-violet.png'),
        iconUrl: require('@/assets/images/markers/marker-icon-violet.png'),
        shadowUrl: require('@/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    },
    redMarker: function () {
      return new L.Icon({
        iconRetinaUrl: require('@/assets/images/markers/marker-icon-2x-red.png'),
        iconUrl: require('@/assets/images/markers/marker-icon-red.png'),
        shadowUrl: require('@/assets/images/markers/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    },
    drawRoute: function (from_latlng, to_latlng) {
      if (routingControl != null) {
        map.removeControl(routingControl)
        routingControl = null
      }

      routingControl = L.Routing.control({
        waypoints: [
          from_latlng,
          to_latlng
        ],
        lineOptions : {
          addWaypoints: false,
        },
        showAlternatives: true,
        fitSelectedRoutes: 'smart',
        useZoomParameter: false,
        createMarker: function() { return null }
      }).addTo(map)
    },
    toggleExtraPollingStations: function () {
      this.resetVolisca()
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  height: calc(100% - 100px);
  width: 100vw;

  @media (max-width: 855px) {
    height: calc(100% - 74px);
  }
}
</style>

<style lang="scss">
@import 'bootstrap/scss/bootstrap';

.hidden-marker {
  display: none !important;
}

.search {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1010;
  transform: translate(-50%, 0);
  margin-top: 32px;

  @media (max-width: 980px) {
    width: 300px;
  }

  @media (max-width: 855px) {
    margin-top: 84px;
  }

  &:before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../assets/images/icon-search.png');
    width: 20px;
    height: 20px;
    left: 10px;
    top: 10px;
    cursor: default;
  }

  .reset {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    color: gray;
    text-decoration: none;
    margin-right: 8px;
    margin-top: -4px;
    cursor: pointer;
  }

  .search-not-found {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 37px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 10px 7px;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
  }

  input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    padding-left: 35px;

    &.street {
      width: 350px;
    }

    &.house-number {
      margin-left: 10px;
      width: 85px;
    }
  }

  button {
    width: 100px;
    margin-left: 10px;
  }

  .search-result {
    position: absolute;
    top: 0;
    margin-top: 37px;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        line-height: 30px;
        cursor: pointer;

      }
    }

    ul.nested {
      li {
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.mr-7 {
  margin-right: 7px;
}

.leaflet-control-attribution {
  font-size: 14px !important;

  a {
    color: #283b45;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
}

.leaflet-control-geosearch form input {
  @extend .form-control;
  padding-right: 32px;
}

.leaflet-control-geosearch .results {
  font-size: 15px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  div:empty {
    display: none;
  }
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 4px 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border: 1px solid lightgray;
  border-top: 0;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

.leaflet-top .leaflet-geosearch-bar,
.leaflet-bottom .leaflet-geosearch-bar {
  display: none;
}

.leaflet-geosearch-bar {
  position: fixed;
  //background-color: white;
  //max-width: 500px;
  //min-width: 300px;
  z-index: 510;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  // border-radius: 0 0 10px 10px;
  // box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.55);
  // @extend .p-3;
  margin-top: 132px;
  width: 400px;

  form {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../assets/images/icon-search.png');
      width: 20px;
      height: 20px;
      left: 10px;
      top: 10px;
      cursor: default;
    }

    input {
      padding-left: 35px;
    }
  }

  @media (max-width: 980px) {
    width: 300px;
  }

  @media (max-width: 855px) {
    margin-top: 84px;
  }
}

.leaflet-control-geosearch a.reset {
  position: absolute;
  text-decoration: none;
  color: gray;
  top: 0;
  right: 0;
  font-size: 30px;
  margin-top: -4px;
  margin-right: 10px;
}

.leaflet-routing-container {
  display: none;
  background: #eaf1f5;
  border: 1px solid lightgray !important;
  border-radius: 0.25rem;
  padding: 15px;

  h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: var(--bs-body-font-size);
  }

  h3 {
    font-weight: 600;
    font-size: var(--bs-body-font-size);
  }

  table {
    font-size: calc(var(--bs-body-font-size) - 2px);

    td {
      padding: 0px;
    }
  }
}

.leaflet-bar {
  border: 1px solid lightgray !important;
}

.leaflet-bottom {
  @media (max-width: 908px) {
    width: 100%;
  }
}

.leaflet-control-attribution {
  font-size: 14px !important;
  background: rgba(255, 255, 255, .95) !important;

  span {
    &:before {
      content: "|";
      color: #bbbdbf;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: -0.1em;
      min-width: .8em;
    }

    &:first-child:before {
      display: none;
    }
  }

  @media (max-width: 908px) {
    font-size: 13px !important;
    text-align: center;
    width: 100%;
    border-top: 1px solid lightgray;

    .last-update {
      display: block;
    }

    span {
      &:nth-child(2):before {
        display: none;
      }
    }
  }
}

.info-box-enter-active,
.info-box-leave-active {
  transition: all .5s ease;
}

.info-box-enter-from,
.info-box-leave-to {
    margin-left: -400px;
}

.info-box-enter-to,
.info-box-leave-from {
  margin-left: initial;
}

.info-box {
  font-family: 'Officina Sans ITC Pro';
  position: absolute;
  left: 0;
  z-index: 1005;
  background-color: rgba(234, 241, 245, .95);
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  padding: 20px 18px 10px 18px;
  width: 300px;
  height: calc(100% - 100px);
  overflow-y: auto;

  @media (max-width: 855px) {
    height: calc(100% - 134px);
    margin-top: 60px;
  }

  .close {
    position: absolute;
    top: 6px;
    right: 14px;

    .arrow {
      border: solid gray;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      cursor: pointer;
    }
  }

  .pre-label {
    color: #283b45;
    padding-bottom:10px;
    font-size: 16px;

    h2 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 16px;
    }

    p, li {
      font-weight: 400;
      font-size: 15px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        padding-left: 30px;
        line-height: 18px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          position: absolute;
          background-repeat: no-repeat;
          left: 3px;
          background-size: contain;
          width: 12px;
          height: 20px;

          top: 50%;
          transform: translateY(-50%);
        }

        &.home-location:before {
          background-image: url('../assets/images/markers/marker-icon-2x-green.png');
        }

        &.your-polling-station:before {
          background-image: url('../assets/images/markers/marker-icon-2x-blue.png');
        }

        &.your-early-polling-station:before {
          background-image: url('../assets/images/markers/marker-icon-2x-grey.png');
        }

        &.search-location:before {
          background-image: url('../assets/images/icon-search.png');
          width: 18px;
          height: 18px;
        }

        &.circle:before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          left: 2px !important;
          border: 1px solid lightgrey;
        }

        &.circle-high:before {
          background-color: #626366;
        }

        &.circle-medium:before {
          background-color: #eaf1f5;
        }

        &.circle-low:before {
          background-color: #7aabbf;
        }

        &.circle-current-location:before {
          background-color: #4286F5;
          border: 2px solid white;
        }
      }
    }
  }

  .toggle-container {
    margin-bottom: 10px;
    &:focus {
      box-shadow: none;
    }

    .toggle {
      border: 1px solid lightgrey;
    }
  }

  div:last-child hr {
    display: none;
  }
}

.polling-stations {
  font-family: 'Officina Sans ITC Pro';
  position: absolute;
  left: 10px;
  // margin-top: 10px;
  top: 110px;
  z-index: 600;
  //text-align: center;
  background-color: white; // #eaf1f5;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 20px 18px 10px 18px;
  max-width: 300px;

  .close {
    position: absolute;
    top: 6px;
    right: 14px;
    display: none;

    .arrow {
      border: solid gray;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      cursor: pointer;
    }
  }

  .pre-label {
    color: #283b45;
    padding-bottom:10px;
    font-size: 16px;

    h2 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .toggle-container {
    margin-bottom: 10px;
    &:focus {
      box-shadow: none;
    }
  }

  .toggle-orange {
    --toggle-bg-on: orange;
    --toggle-border-on: orange;
  }

  .toggle-grey {
    --toggle-bg-on: #7d7d7d;
    --toggle-border-on: #7d7d7d;
  }

  @media (max-width: 980px) {
    top: initial;
    left: initial;
    right: 10px;
    bottom: 30px;
    z-index: 1099;

    .close {
      display: block;
    }

    .arrow {
      transform: rotate(-45deg) !important;
      -webkit-transform: rotate(-45deg) !important;
    }
  }
}

.v-popper--theme-tooltip {
  .v-popper__inner {
    background-color: #eaf1f5 !important;
    padding: 15px !important;
    color: #283b45 !important;
    border: 1px solid lightgray !important;
    border-radius: 0.25rem !important;
    margin-right: 2px;
  }

  .v-popper__arrow-inner, .v-popper__arrow-outer {
    border-color: lightgray !important;
  }
}

.settings-control {
  border-radius: 4px;
  border: 1px solid lightgray;
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 500;
  background-color: #eaf1f5;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;

  @media (max-width: 980px) {
    margin-left: initial;
    margin-top: initial;
    bottom: 162px;
    right: 10px;
  }

  @media (max-width: 376px) {
    bottom: 180px;
  }
}

.info-icon {
  font-weight: 600;
  font-size: 27px;
}

.marker-cluster {
  span {
    display: none;
  }
}

.marker-cluster-large {
  background-color: rgba(98, 99, 102, 0.6);

  div {
    background-color: rgba(98, 99, 102, .7);
  }
}

.marker-cluster-medium {
  background-color: rgba(234, 241, 245, 0.6);

  div {
    background-color: rgba(234, 241, 245, .7);
  }
}

.marker-cluster-small {
  background-color: rgba(122, 171, 191, 0.6);

  div {
    background-color: rgba(122, 171, 191, .7);
  }
}
</style>
