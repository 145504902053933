const translations = {
  sl: {
    common: {
      app_title: 'Državna volilna komisija - Volišča',
      copyright: 'DVK',
      last_update: 'Podatki prikazujejo stanje na dan'
    },
    map: {
      my_location: 'Moja lokacija',
      municipality: 'Občina',
    },
    search: {
      placeholder: {
        street: 'Vpis naslova stalnega bivališča',
        house_number: 'Hišna št.'
      },
      btn_search: 'Najdi volišče',
      not_found: '<b>Naslova ni bilo mogoče najti.</b><br>Potreben je vpis celotnega naslova (ulica, hišna številka, mesto).'
    },
    map_overrides: {
      off: 'Izkl.',
      on: 'Vkl.'
    },
    notifications: {
      title: 'OBVESTILA'
    },
    legend: {
      name: 'Legenda',
      search_location: 'Vpis naslova stalnega bivališča',
      high: 'Velika gostota volišč',
      medium: 'Srednja gostota volišč',
      low: 'Majhna gostota volišč',
      your_polling_station: 'Vaše volišče',
      your_early_polling_station: 'Vaše predčasno volišče',
      home_location: 'Lokacija stalnega bivališča',
      current_location: 'Vaša trenutna lokacija'
    }
  },
  en: {
    common: {
      app_title: 'State Election Commission - Polling stations',
      copyright: 'State Election Commission',
      last_update: 'Last update:'
    },
    map: {
      my_location: 'My Location',
      municipality: 'Municipality',
    },
    search: {
      placeholder: {
        street: 'Street',
        house_number: 'House num.'
      },
      btn_search: 'Search',
      not_found: 'Address not found.'
    },
    map_overrides: {
      off: 'Off',
      on: 'On'
    },
    notifications: {
      title: 'NOTIFICATIONS'
    },
    legend: {
      name: 'Legend',
      search_location: 'Enter your permanent address',
      high: 'High density of polling stations',
      medium: 'Medium density of polling stations',
      low: 'Low density of polling stations',
      your_polling_station: 'Your polling station',
      your_early_polling_station: 'Your polling station for early voting',
      home_location: 'Your permanent residence',
      current_location: 'Your current location'
    }
  },
  it: {
    common: {
      app_title: 'Commissione elettorale nazionale - Seggi elettorali',
      copyright: 'Commissione elettorale nazionale',
      last_update: 'Ultimo aggiornamento:'
    },
    map: {
      my_location: 'La mia posizione',
      municipality: 'Comune',
    },
    search: {
      placeholder: {
        street: 'Strada/Insediamento',
        house_number: 'Numero di casa'
      },
      btn_search: 'Ricerca',
      not_found: 'Indirizzo non trovato.'
    },
    map_overrides: {
      off: 'Off',
      on: 'On'
    },
    notifications: {
      title: 'Notifiche'
    },
    legend: {
      name: 'Leggenda',
      search_location: 'Inserimento del tuo indirizzo permanente',
      high: 'Alta densità di seggi elettorali',
      medium: 'Media densità di seggi elettorali',
      low: 'Bassa densità di seggi elettorali',
      your_polling_station: 'Il tuo seggio elettorale',
      your_early_polling_station: 'Il tuo seggio elettorale per il voto anticipato',
      home_location: 'Luogo di residenza',
      current_location: 'La tua posizione attuale'
    }
  },
  hu: {
    common: {
      app_title: 'Országos Választási Bizottság - Szavazóhelyiségek',
      copyright: 'Országos Választási Bizottság',
      last_update: 'Utolsó frissítés:'
    },
    map: {
      my_location: 'A helyem',
      municipality: 'Község',
    },
    search: {
      placeholder: {
        street: 'Utca/Település',
        house_number: 'Házszám'
      },
      btn_search: 'Keresés',
      not_found: 'Cím nem található'
    },
    map_overrides: {
      off: 'Off',
      on: 'On'
    },
    notifications: {
      title: 'Értesítések'
    },
    legend: {
      name: 'Legenda',
      search_location: 'Írja be az állandó lakcímét',
      high: 'Nagy számú szavazóhelyiség',
      medium: 'Közepes számú szavazóhelyiség',
      low: 'Kevés számú szavazóhelyiség',
      your_polling_station: 'Az Ön szavazóhelyisége',
      your_early_polling_station: 'Az Ön korai szavazásra használt szavazóhelyisége',
      home_location: 'Állandó tartózkodási hely',
      current_location: 'Aktuális tartózkodási helye'
    }
  }
}

export default translations
